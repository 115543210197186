<template>
  <div>
    <div class="mySocial_list">
      <div class="socialInfo_content">
        <div class="socialInfo_left">姓名：</div>
        <div>{{ list.person_name }}</div>
      </div>
      <div class="socialInfo_content">
        <div class="socialInfo_left">本期实发：</div>
        <div>{{ list.thismonthmoney }}</div>
      </div>
      <div class="socialInfo_content">
        <div class="socialInfo_left">时间：</div>
        <div>{{ list.create_time }}</div>
      </div>
      <div class="socialInfo_content">
        <div class="socialInfo_left">基本工资：</div>
        <div>{{}}</div>
      </div>
      <div class="socialInfo_content">
        <div class="socialInfo_left">绩效工资：</div>
        <div>{{}}</div>
      </div>
      <div class="socialInfo_content">
        <div class="socialInfo_left">提成奖金：</div>
        <div>{{}}</div>
      </div>
      <div class="socialInfo_content">
        <div class="socialInfo_left">交通餐补：</div>
        <div>{{}}</div>
      </div>
      <div class="socialInfo_content">
        <div class="socialInfo_left">事假扣除：</div>
        <div>{{}}</div>
      </div>
      <div class="socialInfo_content">
        <div class="socialInfo_left">病假扣除：</div>
        <div>{{}}</div>
      </div>
      <div class="socialInfo_content">
        <div class="socialInfo_left">其他扣除：</div>
        <div>{{}}</div>
      </div>
      <div class="socialInfo_content">
        <div class="socialInfo_left">本期收入：</div>
        <div>{{ list.basesalary }}</div>
      </div>
      <div class="socialInfo_content">
        <div class="socialInfo_left">养老保险：</div>
        <div>{{}}</div>
      </div>
      <div class="socialInfo_content">
        <div class="socialInfo_left">医疗保险：</div>
        <div>{{}}</div>
      </div>
      <div class="socialInfo_content">
        <div class="socialInfo_left">失业保险：</div>
        <div>{{}}</div>
      </div>
      <div class="socialInfo_content">
        <div class="socialInfo_left">大病医疗：</div>
        <div>{{}}</div>
      </div>
      <div class="socialInfo_content">
        <div class="socialInfo_left">个税扣除：</div>
        <div>{{}}</div>
      </div>
      <div class="socialInfo_content">
        <div class="socialInfo_left">其他扣除：</div>
        <div>{{}}</div>
      </div>
    </div>
  </div>
</template>
<script>
// import { formatMinute } from "../utils/tool";
export default {
  data() {
    return {
      list: {
        id: 1,
        name: "张三",
        card: "32**************21",
        city: "园区乙类",
        month: "2022年01月",
        type: "社保+公积金",
        status: 0,
        endowment: "11.00", //养老保险
        medical: "11.00", //医疗保险
        unemployment: "11.00", //失业保险
        employmentInjury: "11.00", //工伤保险
        maternity: "11.00", //生育保险
        accumulationFund: "11.00", //公积金
      },
    };
  },
  mounted() {
    let pid = localStorage.getItem("pid");
    let id = localStorage.getItem("id");
    this.pid = pid;
    // 测试
    // this.pid=53738;
    this.id = id;
    this.infoId = this.$route.params.id;
  },
  methods: {},
};
</script>
<style scoped>
.mySocial_list {
  border: 0.0625rem solid #ddd;
  width: calc(100% - 1.875rem);
  margin: 0 auto;
  margin-top: 0.625rem;
  border-radius: 0.9375rem;
  padding: 0.3125rem 0.9375rem 1.875rem 0.9375rem;
  box-sizing: border-box;
  font-size: 0.9375rem;
  box-shadow: 0.3125rem 0.3125rem 0.3125rem rgb(0 0 0 / 18%);
}

.socialInfo_content {
  border-bottom: 0.0625rem solid #ddd;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  line-height: 2.1875rem;
}
.socialInfo_left {
  width: 5rem;
}
</style>

